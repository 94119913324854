import React, { useState, useRef } from 'react';
import HeaderIcon1 from '../../assets/darktheme/header-1.svg';
import HeaderIcon2 from '../../assets/darktheme/header-2.svg';
import HeaderIcon3 from '../../assets/darktheme/header-3.svg';

const formatClassName = (className) => {
  return className.split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const PanelHeader = ({ className }) => {
  const initialDisplayName = formatClassName(className);
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(initialDisplayName);
  const inputRef = useRef(null);

  const handleIconClick = (iconNumber) => {
    console.log(`Icon ${iconNumber} clicked`);
  };

  const handleNameClick = () => {
    setIsEditing(true);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleNameBlur = () => {
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
    }
  };

  return (
    <div className="panel-header">
      <div className="panel-name">
        {isEditing ? (
          <input
            ref={inputRef}
            type="text"
            value={name}
            onChange={handleNameChange}
            onBlur={handleNameBlur}
            onKeyDown={handleKeyDown}
            className="panel-name-input"
          />
        ) : (
          <span onClick={handleNameClick}>{name}</span>
        )}
      </div>
      <div className="panel-icons">
        <button onClick={() => handleIconClick(1)} className="panel-icon-button">
          <img src={HeaderIcon1} alt="Icon 1" className="panel-icon" />
        </button>
        <button onClick={() => handleIconClick(2)} className="panel-icon-button">
          <img src={HeaderIcon2} alt="Icon 2" className="panel-icon" />
        </button>
        <button onClick={() => handleIconClick(3)} className="panel-icon-button">
          <img src={HeaderIcon3} alt="Icon 3" className="panel-icon" />
        </button>
      </div>
    </div>
  );
};

export default PanelHeader;