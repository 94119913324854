import React from 'react';
import './NavigationBar.css';
import Menubar from './Menubar';
import SearchBar from './SearchBar';
import Toolbar from './Toolbar';
import collapsedMenu from '../../assets/darktheme/toolbar/collapsed-menu.svg';

const NavigationBar = () => {
  return (
    <div className="navigation-bar">
      <Menubar className="menubar"/>
      {/* <SearchBar className="searchbar"/> */}
      <Toolbar className="toolbar"/>
      <img src={collapsedMenu} alt="menu" className="collapsed-menu-icon" />
    </div>
  );
};

export default NavigationBar;