import React, { useEffect, useRef, useState } from 'react';
import './Visualization.css';
import * as VizFlags from './visualization-helpers/visualizationFlags';
import * as Viz from './visualization-helpers/vizHelperFunctions';
import { fetchDataType } from './visualization-helpers/fetchData';
import * as d3 from 'd3';
import datamock from './visualization-helpers/data';

const Visualization = ({ panelName, data, vizdata }) => {
  const svgRef = useRef();
  const divRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // Handle resizing of the Visualization
  useEffect(() => {
    // Set initial dimensions
    const resizeHandler = () => Viz.handleResize(divRef, setDimensions);
    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  // Fetch data types for the data columns
  const [dataType, setDataType] = useState(null);
  useEffect(() => {
    const loadDataType = async () => {
      const result = await fetchDataType();
      if (result) {
        setDataType(result);
      }
    };

    loadDataType();
  }, []);

  // Create Visualization
  useEffect(() => {
    if (data && dataType && dataType.column_data_types){
      let data = datamock;
      if (VizFlags.VizSVG.includes(panelName.trim())) {
        var margin = { top: 30, right: 20, bottom: 50, left: 70 };
        var innerWidth = dimensions.width - margin.left - margin.right;
        var innerHeight = dimensions.height - margin.top - margin.bottom;
        var innerDimensions = { width: innerWidth, height: innerHeight };
        let scales = {xScale: null, yScale: null, zScale: null};

        // Clear existing SVG content before creating new SVG elements
        d3.select(svgRef.current).selectAll('*').remove();

        var svg = Viz.createSVG(svgRef, innerDimensions, margin);
        if(VizFlags.VizXaxis.includes(panelName.trim())) {
          const xReturn = Viz.createXAxis(
            svg,
            data.data, 
            vizdata.xaxis, 
            dataType.column_data_types[vizdata.xaxis], 
            innerDimensions, 
            margin,
            VizFlags.VizXGrid.includes(panelName.trim())
          );
          svg = xReturn.svg;
          scales.xScale = xReturn.xScale;
        }

        if(VizFlags.VizYaxis.includes(panelName.trim())) {
          const yReturn = Viz.createYAxis(
            svg,
            data.data, 
            vizdata.yaxis, 
            dataType.column_data_types[vizdata.yaxis], 
            innerDimensions, 
            margin,
            VizFlags.VizYGrid.includes(panelName.trim())
          );
          svg = yReturn.svg;
          scales.yScale = yReturn.yScale;
        }
        
        switch(panelName.trim()) {
          case 'bubble-chart':
            Viz.renderBubbleChart(svg,
              vizdata,
              data.data,
              scales
            );
            break;
          
          case 'pie-chart':
            Viz.renderPieChart(svg,
              dimensions,
              vizdata,
              data.data

            );
            break;
            
          case 'box-plot':
            Viz.renderBoxPlot(svg,
              vizdata,
              data.data,
              scales
            );
            break;

          case 'line-chart':
            Viz.renderAreaChart(svg,
              innerDimensions,
              margin,
              vizdata,
              data.data,
              scales
            );
            break;
          
          default:
            break;
        }
      }
    }
  }, [data, dimensions, panelName, dataType, vizdata]);

  return (
    <div className={`visualization visualization-${panelName}`} ref={divRef}>
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default Visualization;