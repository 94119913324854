import React from 'react';
import './Panel.css';
import PanelHeader from './PanelHeader';
import Visualization from '../visualizations/Visualization';

const Panel = React.forwardRef(({ className, style, data, vizdata}, ref) => {

  return (
    <div ref={ref} className={`panel ${className || ''}`} style={style}>
      <PanelHeader className={className} />
      <Visualization panelName={className} data={data} vizdata={vizdata}/> 
    </div>
  );
});

export default Panel;