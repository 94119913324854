import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import LandingPanels from './components/structural/LandingPanels';
import NavigationBar from './components/navigation/NavigationBar';
import {fetchData} from './components/visualizations/visualization-helpers/fetchData';

function App() {
  const viewportRef = useRef(null);
  const [data, setData] = useState(null);

  // Loading the data for the dashboard
  useEffect(() => {
    const loadData = async () => {
      const result = await fetchData();
      if (result) {
        setData(result);
      }
    };

    loadData();
  }, []);

  return (
    <div className="app">
      <div className="menu">
        <NavigationBar/>
      </div>
      <div className="dashboard">
        <div className="tabs" />
        <div className="viewport-parent">
          <LandingPanels viewportRef={viewportRef} data={data}/>
          </div>
        </div>
      </div>
  );
}

export default App;