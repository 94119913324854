import React from 'react';
import './Menubar.css';
import homeIcon from '../../assets/darktheme/menubar/home.svg';
import aiIcon from '../../assets/darktheme/menubar/AI.svg';
import dataIcon from '../../assets/darktheme/menubar/data.svg';
import filterIcon from '../../assets/darktheme/menubar/filter.svg';
import exportIcon from '../../assets/darktheme/menubar/export.svg';
import shareIcon from '../../assets/darktheme/menubar/share.svg';
import saveIcon from '../../assets/darktheme/menubar/save.svg';
import panelIcon from '../../assets/darktheme/menubar/panel.svg';

const menuItems = [
  { name: 'Project', icon: homeIcon },
  { name: 'AI', icon: aiIcon },
  { name: 'Data', icon: dataIcon },
  { name: 'Filters', icon: filterIcon },
  { name: 'Export', icon: exportIcon },
  { name: 'Share', icon: shareIcon },
  { name: 'Save', icon: saveIcon },
  { name: 'Add Panel', icon: panelIcon },
];

const Menubar = () => {

  return (
      <div className="menubar">
        {menuItems.map((item, index) => (
          <div className="menu-item" key={index}>
            <img src={item.icon} alt={`${item.name} icon`} className="menu-icon" />
          <span className="menu-label">{item.name}</span>
          </div>
        ))}
    </div>
  );
};

export default Menubar;
