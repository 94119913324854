import React, { useState, useEffect } from 'react';
import './LandingPanels.css';
import Panel from './Panel';
import landingpaneldata from './landingPanelData';

const DefaultComponents = ({ viewportRef, data}) => {
  
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [layoutChanged, setLayoutChanged] = useState(false);

  useEffect(() => {
    const handleClick = () => setContextMenuVisible(false);
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, []);

  const handleContextMenu = (e) => {
    e.preventDefault();
    setContextMenuPosition({ x: e.pageX, y: e.pageY });
    setContextMenuVisible(true);
  };

  const changeGridLayout = () => {
    setLayoutChanged(!layoutChanged);
    setContextMenuVisible(false);
  };

  return (
    <div className="viewport" ref={viewportRef} onContextMenu={handleContextMenu}>
      <Panel className={`map ${layoutChanged ? 'new-map' : ''}`} data={data} vizdata = {landingpaneldata.map}/>
      <Panel className={`bubble-chart ${layoutChanged ? 'new-bubble-chart' : ''}`} data={data} vizdata = {landingpaneldata.bubblechart}/>
      <Panel className={`pie-chart ${layoutChanged ? 'new-pie-chart' : ''}`} data={data} vizdata = {landingpaneldata.piechart}/>
      <Panel className={`line-chart ${layoutChanged ? 'new-line-chart' : ''}`} data={data} vizdata = {landingpaneldata.linechart}/>
      <Panel className={`data-viewer ${layoutChanged ? 'new-data-viewer' : ''}`} data={data} vizdata = {landingpaneldata.dataviewer}/>
      <Panel className={`box-plot ${layoutChanged ? 'new-box-plot' : ''}`} data={data} vizdata = {landingpaneldata.boxplot}/>
      {contextMenuVisible && (
        <ul className="context-menu" style={{ top: contextMenuPosition.y, left: contextMenuPosition.x }}>
          <li onClick={changeGridLayout}>Change Layout</li>
        </ul>
      )}
    </div>
  );
};

export default DefaultComponents;