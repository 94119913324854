const BASE_URL = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : '';

// This function fetches the data from the API
export const fetchData = async () => {
    try {
      console.log(BASE_URL);
      const response = await fetch(`${BASE_URL}/api/data`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      return null;
    }
  };

  // This function fetches the columns data types from the API
  export const fetchDataType = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/columns/types`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const datatype = await response.json();
      return datatype;
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      return null;
    }
};

export const fetchDataMock = async () => {
  try {
    const response = await fetch('./data.json');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};
  