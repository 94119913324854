const data ={
  "data": [
    {
      "med_wcf_tvd_FT": 8000,
      "wdpg_wellStatus": "Active",
      "wdpg_permitDistrict": "District A",
      "wdpt_permitIssueDate": "2023-01-15",
      "med_wcf_totalBaseWaterVol_GAL": 15000,
      "med_wcf_percentHfJob_PERC": 60,
      "med_wcf_totalBaseNonWaterVol_GAL": 5000
    },
    {
      "med_wcf_tvd_FT": 7500,
      "wdpg_wellStatus": "Inactive",
      "wdpg_permitDistrict": "District A",
      "wdpt_permitIssueDate": "2023-03-10",
      "med_wcf_totalBaseWaterVol_GAL": 14000,
      "med_wcf_percentHfJob_PERC": 65,
      "med_wcf_totalBaseNonWaterVol_GAL": 4800
    },
    {
      "med_wcf_tvd_FT": 8200,
      "wdpg_wellStatus": "Active",
      "wdpg_permitDistrict": "District A",
      "wdpt_permitIssueDate": "2023-05-20",
      "med_wcf_totalBaseWaterVol_GAL": 16000,
      "med_wcf_percentHfJob_PERC": 70,
      "med_wcf_totalBaseNonWaterVol_GAL": 5200
    },
    {
      "med_wcf_tvd_FT": 7800,
      "wdpg_wellStatus": "Inactive",
      "wdpg_permitDistrict": "District B",
      "wdpt_permitIssueDate": "2023-07-30",
      "med_wcf_totalBaseWaterVol_GAL": 15500,
      "med_wcf_percentHfJob_PERC": 68,
      "med_wcf_totalBaseNonWaterVol_GAL": 5100
    },
    {
      "med_wcf_tvd_FT": 7900,
      "wdpg_wellStatus": "Active",
      "wdpg_permitDistrict": "District B",
      "wdpt_permitIssueDate": "2023-09-25",
      "med_wcf_totalBaseWaterVol_GAL": 14500,
      "med_wcf_percentHfJob_PERC": 62,
      "med_wcf_totalBaseNonWaterVol_GAL": 4900
    },
    {
      "med_wcf_tvd_FT": 8100,
      "wdpg_wellStatus": "Inert",
      "wdpg_permitDistrict": "District B",
      "wdpt_permitIssueDate": "2023-11-10",
      "med_wcf_totalBaseWaterVol_GAL": 15800,
      "med_wcf_percentHfJob_PERC": 64,
      "med_wcf_totalBaseNonWaterVol_GAL": 5150
    },
    {
      "med_wcf_tvd_FT": 7700,
      "wdpg_wellStatus": "Inert",
      "wdpg_permitDistrict": "District C",
      "wdpt_permitIssueDate": "2024-01-05",
      "med_wcf_totalBaseWaterVol_GAL": 14300,
      "med_wcf_percentHfJob_PERC": 67,
      "med_wcf_totalBaseNonWaterVol_GAL": 4850
    },
    {
      "med_wcf_tvd_FT": 8300,
      "wdpg_wellStatus": "Inert",
      "wdpg_permitDistrict": "District C",
      "wdpt_permitIssueDate": "2024-02-20",
      "med_wcf_totalBaseWaterVol_GAL": 16200,
      "med_wcf_percentHfJob_PERC": 69,
      "med_wcf_totalBaseNonWaterVol_GAL": 5300
    },
    {
      "med_wcf_tvd_FT": 7600,
      "wdpg_wellStatus": "Inactive",
      "wdpg_permitDistrict": "District D",
      "wdpt_permitIssueDate": "2024-04-15",
      "med_wcf_totalBaseWaterVol_GAL": 14800,
      "med_wcf_percentHfJob_PERC": 66,
      "med_wcf_totalBaseNonWaterVol_GAL": 4950
    },
    {
      "med_wcf_tvd_FT": 8400,
      "wdpg_wellStatus": "Active",
      "wdpg_permitDistrict": "District D",
      "wdpt_permitIssueDate": "2024-06-10",
      "med_wcf_totalBaseWaterVol_GAL": 16400,
      "med_wcf_percentHfJob_PERC": 71,
      "med_wcf_totalBaseNonWaterVol_GAL": 5400
    },
    {
      "med_wcf_tvd_FT": 7850,
      "wdpg_wellStatus": "Active",
      "wdpg_permitDistrict": "District D",
      "wdpt_permitIssueDate": "2024-07-25",
      "med_wcf_totalBaseWaterVol_GAL": 15000,
      "med_wcf_percentHfJob_PERC": 63,
      "med_wcf_totalBaseNonWaterVol_GAL": 5000
    },
    {
      "med_wcf_tvd_FT": 7950,
      "wdpg_wellStatus": "Inactive",
      "wdpg_permitDistrict": "District D",
      "wdpt_permitIssueDate": "2024-09-05",
      "med_wcf_totalBaseWaterVol_GAL": 14700,
      "med_wcf_percentHfJob_PERC": 65,
      "med_wcf_totalBaseNonWaterVol_GAL": 4900
    },
    {
      "med_wcf_tvd_FT": 8200,
      "wdpg_wellStatus": "Active",
      "wdpg_permitDistrict": "District E",
      "wdpt_permitIssueDate": "2024-10-20",
      "med_wcf_totalBaseWaterVol_GAL": 15500,
      "med_wcf_percentHfJob_PERC": 68,
      "med_wcf_totalBaseNonWaterVol_GAL": 5100
    },
    {
      "med_wcf_tvd_FT": 8050,
      "wdpg_wellStatus": "Inactive",
      "wdpg_permitDistrict": "District E",
      "wdpt_permitIssueDate": "2024-12-15",
      "med_wcf_totalBaseWaterVol_GAL": 14900,
      "med_wcf_percentHfJob_PERC": 64,
      "med_wcf_totalBaseNonWaterVol_GAL": 4950
    },
    {
      "med_wcf_tvd_FT": 8150,
      "wdpg_wellStatus": "Active",
      "wdpg_permitDistrict": "District A",
      "wdpt_permitIssueDate": "2025-02-05",
      "med_wcf_totalBaseWaterVol_GAL": 15700,
      "med_wcf_percentHfJob_PERC": 67,
      "med_wcf_totalBaseNonWaterVol_GAL": 5050
    }
  ]
}

export default data;
