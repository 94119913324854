import React from 'react';
import './Toolbar.css';
import settingsIcon from '../../assets/darktheme/toolbar/settings.svg';
import themeIcon from '../../assets/darktheme/toolbar/theme.svg';
import notificationsIcon from '../../assets/darktheme/toolbar/notifications.svg';
import profileIcon from '../../assets/darktheme/toolbar/profile.svg';

const Toolbar = () => {

  return (
    <div className="toolbar">
    <div className="sign-in">
    <img src={profileIcon} alt="profile" className="profile-button" />
      <span className="sign-in-text"> Sign In </span>
    </div>
    <img src={settingsIcon} alt="settings" className="settings-button" />
    <img src={notificationsIcon} alt="notifications" className="notification-button" />
    <img src={themeIcon} alt="mode" className="display-mode" />
  </div>
  );
};

export default Toolbar;
