import { map } from "d3";

const landingpaneldata = {
    map: {
        xaxis: null,
        yaxis: null,
        zaxis: null,
        color: null
    },

    bubblechart: {
        xaxis: 'med_wcf_tvd_FT',
        yaxis: 'med_wcf_totalBaseWaterVol_GAL',
        zaxis: 'med_wcf_percentHfJob_PERC',
        color: 'wdpg_permitDistrict'
    },

    piechart: {
        xaxis: 'wdpg_wellStatus',
        yaxis: null,
        zaxis: null,
        color: null
    },

    linechart: {
        // Temporary for mock data
        xaxis: 'wdpt_permitIssueDate',

        // xaxis: 'wcf_jobStartDate',
        yaxis: 'med_wcf_totalBaseWaterVol_GAL',
        zaxis: 'med_wcf_totalBaseNonWaterVol_GAL',
        color: null
    },

    boxplot: {
        xaxis: 'wdpg_permitDistrict',
        yaxis: 'med_wcf_totalBaseWaterVol_GAL',
        zaxis: null,
        color: "wdpg_permitDistrict"
    },

    dataviewer: {
        xaxis: null,
        yaxis: null,
        zaxis: null,
        color: null
    }
};

export default landingpaneldata;